.individual-view-access-code-modal {
    .react-bs-table-bordered {
        border: 0px !important;
    }

    .modal-herader {
        height: 45px !important;
    }
    .modal-content {
        width: 1000px;
        height: 440px;
    }

    .modal-body {
        table {
            colgroup {
                background: white;
            }
            thead {
                border-bottom: 2px solid #202428;
                th {
                    border: 0px !important;
                    font-family: Mulish;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    text-align: left;
                }
            }

            tbody {
                td {
                    border: 0px !important;
                    font-family: Mulish;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;

                    a {
                        color: #05386b;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .custom-ellipsis {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        width: 100%;
                    }
                }
                tr {
                    border-bottom: 1px solid #a6a9ac;
                }
                .expired {
                    width: 73px;
                    height: 23px;
                    padding: 1px 8px;
                    border-radius: 4px;
                    border: 1px solid #8b1655;
                    background-color: #f7e8f0;
                    color: #8b1655;
                }

                .authenticated {
                    width: 108px;
                    height: 23px;
                    padding: 1px 8px;
                    border-radius: 4px;
                    background: #e6f1f8;
                    border: 1px solid #075c95;
                    color: #075c95;
                }
                .active {
                    width: 46px;
                    height: 23px;
                    padding: 1px 8px;
                    border-radius: 4px;
                    border: 1px solid #47682d;
                    background: #f0f4ec;
                    color: #47682d;
                }

                .notRequested {
                    width: 46px;
                    height: 23px;
                    padding: 1px 8px;
                    border-radius: 4px;
                    border: 1px solid #565a5e;
                    background: #f0f1f1;
                    color: #565a5e;
                }
                .status-text {
                    font-family: Mulish;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: left;
                }
            }
        }
        .nav.nav-tabs > a.nav-item:hover,
        .nav.nav-tabs > a.nav-item:focus,
        .nav.nav-tabs > a.nav-item.active {
            border-bottom: 3px solid #0973ba !important;
            border-top: 0px !important ;
            border-left: 0px !important ;
            border-right: 0px !important ;
            margin-bottom: 0px !important;

            .view-acces-code-tab {
                font-family: Mulish;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-align: left;
                color: #0973ba;
            }
        }

        .view-acces-code-tab {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #212529;
        }

        .alert {
            margin: 20px 0px 0px 0px !important;
        }

        .alert-success {
            border-left: 5px solid #1f8747 !important;
            border-radius: 4px;
        }

        .alert-info {
            border-left: 5px solid #0973ba !important;
            border-radius: 4px;
        }
        .toaster-message {
            font-family: Mulish;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            margin-left: 9px;
            color: #212529;
        }
    }
    .view-acces-code-header {
        font-family: Mulish;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #212529;
    }

    .tab-content > .active,
    .nav-tabs > .active {
        background-color: #ffff !important;
    }

    .close-button {
        border: 1px solid #898d91;
        background: #f0f1f1;
        border-radius: 2px !important;
        color: #202223;
        width: 100px;
        height: 38px;
        float: right;
        padding: 6px 12px 6px 12px;
    }

    .close-button-text {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #212529;
    }
}
