$primary-error: #CC4A43;

.change-office-location-modal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 3.5rem);
    margin: 1.75rem auto;
    width: 100%;
    height: 100%;
}

.change-office-location-modal .modal-dialog .modal-content {
    border-radius: 4.8px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    width: 700px;
    height: auto;
    box-shadow: none;
}


.change-office-location-modal .modal-dialog .modal-content .modal-header {
    background: #ffffff;
    border-bottom: 1px solid #DEE2E6;
    line-height: 24px;
    font-size: 20px;
    padding: 9px 1rem;
    border-top-left-radius: 4.8px;
    border-top-right-radius: 4.8px;
}

.change-office-location-modal .modal-dialog .modal-content .modal-header .closeIcon {
    fill: #808080;
    width: 26px;
    line-height: 24px;
    cursor: pointer;
}

.change-office-location-modal .modal-dialog .modal-content .modal-header .modal-title {
    line-height: 120%;
    font-size: 16px;
    color: #1a8fbf;
    font-weight: 700;
    font-family: 'Mulish', sans-serif !important;
}

.change-office-location-modal .modal-dialog .modal-content .modal-body {
    font-size: 14px;
    line-height: 150%;
    background: #fff;
    overflow-y: auto;
    max-height: calc(100vh - 12rem);
}

.change-office-location-modal .modal-dialog .modal-content .modal-footer {
    background: #fff;
}

.change-office-location-modal p{
    text-align: justify;
}

.change-office-location-modal .fieldErrorBorder {
    border: 1px solid $primary-error !important;
}

.change-office-location-modal .fieldErrorMessage {
    color: $primary-error;
    padding: 4px 0 0;
    display: block;
    font-size: 14px;
}

.change-office-location-modal .showError {
    display: block;
}

.custom-modal-note {
    font-family: Mulish;
    font-size: 13px;
    font-Weight: 700;
    line-height : 16.32px;
    text-align: left;
    color: #AB7F2D;
}
