.dragAndDropContainer {
    display: flex;
    justify-content: space-between;
    position: relative;

    article {
        width: 350px;

        span {
            font-family: 'Mulish';
            font-weight: 700;
            font-size: 12px;
            color: #212529;
            margin-right: 6px;

            svg {
                margin-top: -3px;
                cursor: pointer;
            }
        }

        ul::-webkit-scrollbar {
            width: 0.3rem;
        }

        ul::-webkit-scrollbar-thumb {
            background-color: #0973ba86;
            border-radius: 3px;
        }

        ul::-webkit-scrollbar-thumb:hover {
            background-color: #0973BA;
            border-radius: 3px;
        }

        ul {
            padding: 0px;
            border: 1px solid #A6A9AC;
            height: 331px;
            overflow-y: auto;
            margin: 0.6rem 0 0 0;
            scrollbar-width: thin;
        }
    }

    .resetButton {
        position: absolute;
        right: 0px;
        top: -4px;
        display: flex;
        align-items: center;

        .resetText {
            font-size: 1rem;
            font-weight: 400;
            color: #0973BA;
            margin-left: 0.5rem;
            text-decoration: underline;
        }
    }
}