@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../font/glyphicons-halflings-regular.eot');
    src: url('../font/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
        url('../font/glyphicons-halflings-regular.ttf') format('truetype'),
        url('../font/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-remove:before {
    content: "\e014"
}

