.setAccessBody::-webkit-scrollbar {
    width: 0.4rem;
}

.setAccessBody::-webkit-scrollbar-thumb {
    background-color: #0973ba86;
    border-radius: 3px;
}

.setAccessBody::-webkit-scrollbar-thumb:hover {
    background-color: #0973BA;
    border-radius: 3px;
}

.setAccessBody {
    background-color: white;
    height: auto;
    overflow-y: auto;
    scrollbar-width: thin;

    .selectionData {
        height: 56px;
        border-radius: 4px;
        border-left: 5px solid #0973BA;
        background-color: #E6F1F8;
        display: flex;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;

        span {
            margin-left: 0.5rem;
        }

        span:first-child {
            margin-top: -4px;
        }
    }

    .usersDnD {
        padding: 1rem 0 0 0;
    }

    .userGroupsDnD {
        padding: 1rem 0;

        :global .eachGroupSetAccess {
            display: inline-block;
            align-items: center;
            height: 23px;
            width: auto;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 4px;
            padding: 1px 8px;
            font-weight: 400;
            font-size: 14px;
        }
    }

    .errorMessage {
        color: #cc4a43;
        font-size: 14px;
        font-weight: 400;
        margin-left: 406px;
        margin-top: -9px;
    }
}

.setAccessFooter {
    background-color: white;

    div {
        display: flex;
        justify-content: flex-end;

        .resetButton {
            border: none;
            background-color: #FFF;
            margin-right: 1rem;
            cursor: pointer;

            .resetText {
                display: inline-block;
                cursor: pointer;
                color: #0973BA;
                text-decoration: underline;
                font-size: 1rem;
                font-weight: 400;
            }
        }

        .cancelButton {
            width: 100px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 400;
            background-color: #F0F1F1;
            border: 1px solid #898D91;
            border-radius: 2px !important;
            color: #212529;
            padding: 6px 12px 6px 12px;
            cursor: pointer;
            box-shadow: none;
        }

        .saveButton {
            width: 100px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 400;
            background-color: #0973BA;
            border: 1px solid #0973BA;
            border-radius: 2px !important;
            color: #FFFFFF;
            padding: 6px 12px 6px 12px;
            cursor: pointer;
            box-shadow: none;
        }

        .disabledSaveButton {
            cursor: not-allowed !important;
            opacity: 0.65;
        }
    }
}