:root {
    --input-border-color: #898D91;
    --primary-blue: #0973BA;
}

/* Scroll Style Starts */

.mismatchModal ::-webkit-scrollbar {
    width: 8px;
    background-color: inherit;
}

.mismatchModal ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f6f6f6;
    border-radius: 10px;
}

.mismatchModal ::-webkit-scrollbar-thumb {
    background: var(--primary-blue);
    border-radius: 10px;
}

.mismatchModal ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-blue);
}

/* Scroll Style Ends */

.difference {
    color: #cc4a43 !important;
    font-weight: 600;
}

.mismatchModal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: calc(100vh - 3.5rem);
    margin: 1.75rem auto;
    width: 100%;
    height: 100%;
}

.mismatchModal .modal-dialog .modal-content {
    border-radius: 4.8px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    width: 700px;
    height: auto;
    box-shadow: none;
}

.mismatchModal .modal-dialog .modal-content .modal-header {
    background: #ffffff;
    border-bottom: 1px solid #DEE2E6;
    line-height: 24px;
    font-size: 20px;
    padding: 9px 1rem;
    border-top-left-radius: 4.8px;
    border-top-right-radius: 4.8px;
}

.mismatchModal .modal-dialog .modal-content .modal-header .closeIcon {
    line-height: 20px;
    cursor: pointer;
}

.mismatchModal .modal-dialog .modal-content .modal-header .modal-title {
    line-height: 120%;
    font-size: 20px;
    color: #212529;
    font-weight: 700;
    font-family: 'Mulish', sans-serif !important;
}

.mismatchModal .modal-dialog .modal-content .modal-body {
    font-size: 16px;
    line-height: 150%;
    background: #fff;
    overflow-y: auto;
    max-height: calc(100vh - 12rem);
}

.mismatchModal .modal-dialog .modal-content .modal-footer {
    background: #fff;
}

.mismatchModal .modal-dialog .modal-content .modal-footer .btn-secondary {
    background: #F0F1F1;
    border: 1px solid var(--input-border-color);
    border-radius: 2px !important;
    color: #212529;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Mulish', sans-serif !important;
}

.mismatchModal .modal-dialog .modal-content .modal-footer .btn-primary {
    background: var(--primary-blue);
    border: 1px solid var(--primary-blue);
    border-radius: 2px !important;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 150%;
    font-family: 'Mulish', sans-serif !important;
}

.mismatchModal .modal-dialog .modal-content .modal-footer .doNotUpdate {
    padding: 6px 9px;
}

.mismatchModal .modal-dialog .modal-content .modal-footer .update {
    padding: 6px 35px;
}

.clientsListTable {
    table-layout: fixed;
    color: #212529;
    font-size: max(min(2.1vw, 1rem), 12px);
    line-height: 1rem;
    border-spacing: 0px;
    margin: 15px 0 0 0;
    border-top: 0;
}

.clientsListTable thead {
    border-bottom: 2px solid #212529;
}

.clientsListTable th {
    color: #212529;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;
    border-top: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.clientsListTable th:nth-child(2) {
    text-align: center;
}

.clientsListTable th:last-child {
    background-color: #E6EBF080;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.clientsListTable th.widthAuto {
    width: 100%;
}

.clientsListTable th.width70 {
    width: 70%;
}

.clientsListTable tbody .clientsLoadingArea {
    vertical-align: middle;
    color: var(--primary-blue) !important;
}

.clientsListTable tbody .clientsLoadingArea .spinner-border {
    border-right-color: lightgray;
    border-bottom-color: lightgray;
    border-left-color: lightgray;
}

.clientsListTable tbody .clientsLoadingArea .clientsLoadingText {
    color: rgb(136, 136, 136);
    margin-left: 6px;
}

.clientsListTable tbody tr.clientRow td {
    font-size: 16px;
    line-height: 150%;
    vertical-align: middle;
    background-color: #fff;
    padding: 3.5px 10px 3.5px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-top: 1px solid #A6A9AC;
}

.clientsListTable tbody tr.clientRow td:first-child {
    color: #6B7075;
}

.clientsListTable tbody tr.clientRow td:nth-child(2) {
    text-align: center;
    padding: 0 10px;
}

.clientsListTable tbody tr.clientRow td:last-child {
    background-color: #E6EBF080;
    text-align: center;
    padding: 0 10px;
}

.clientsListTable tbody tr.clientRow td.widthAuto,
.clientsListTable tbody tr.clientRow td.width9,
.clientsListTable tbody tr.clientRow td.width10,
.clientsListTable tbody tr.clientRow td.width20 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.clientsListTable tbody tr.clientRow td.actionsColData {
    font-size: max(min(1.9vw, 1rem), 12px);
    padding: 4px 12px 3px 4px;
}
