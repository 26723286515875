body {
    overflow: hidden !important;
}

#divLoading {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    display: none;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#divLoading::after {
    content: "";
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border: 3px solid #eee;
    border-width: 3px;
    border-radius: 50%;
    border-top-color: #07d !important;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}

.loader-text {
    text-align: center;
    margin-top: 22%;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /*margin-top: 62px;*/
}

.dropup,
.dropright,
.layout-dropdown,
.dropleft {
    position: relative;
}

.layout-dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.layout-dropdown-toggle:empty::after {
    margin-left: 0;
}

.layout-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.layout-dropdown-menu-right {
    left: auto;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-size: 14px;
}

.layout-dropdown-menu-right span {
    margin: 10px;
    color: black;
}

.layout-dropdown-menu-right i {
    color: #555464 !important;
    text-shadow: #ffffff 1px 1px 1px;
}

.app-header-menu {
    height: 48px;
}

.app-header-menu .app-header-menu-item {
    padding: 3px 15px;
    cursor: pointer;
    color: black;
}

.app-header-menu .app-header-menu-item .dropdown-menu {
    min-width: 12rem;
}

.app-header-menu .app-header-menu-item i {
    color: #555464 !important;
    text-shadow: #ffffff 1px 1px 1px;
}

.app-header-menu .app-header-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.app-header-menu .app-header-menu-item ul li .ddl-icon {
    margin-left: 10px;
    padding: 7px 0;
}

.app-header-menu .dropdown-toggle {
    font-size: 15px;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    /*color: #555464 !important;*/
    text-shadow: #ffffff 1px 1px 1px;
}

.nav-link span {
    color: black;
    text-shadow: none;
    white-space: nowrap;
    font-size: 14px;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.ml-auto {
    float: right;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        max-width: 900px;
    }
}

.breadcrumb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 1.75rem 2rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: white;
    border-radius: 0px !important;
    border-bottom: 1px solid #e8e8e8;
    height: 50px;
    font-size: 1.25rem;
    position: fixed;
    width: 100%;
    z-index: 10;
    box-shadow: 0 0 8px #dcdcdc;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.breadcrumb-item a {
    color: teal;
}

#wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

#wrapper #content-wrapper {
    overflow-x: hidden;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 70px);
}

.download-page {
    width: 60%;
    margin-top: 10%;
    margin-left: 15%;
    font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica,
        Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border: 1px solid #e5e5e5;
    padding: 10px 50px 50px 50px;
}

.layout-dropdown-menu .layout-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem 0.25rem 2.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    /*background-color: transparent;*/
    border: 0;
    font-size: 14px;
}

.layout-dropdown-menu .layout-dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
    text-decoration: none;
}

.layout-dropdown-menu .active {
    background-color: rgba(0, 0, 0, 0.2);
    border-left: 2px solid blue;
}

.toggled .layout-dropdown .layout-dropdown-menu .layout-dropdown-item {
    padding-left: 1.5rem;
}

.form-control,
.react-bs-table-bordered,
.btn,
.Select-control {
    border-radius: 0px !important;
}

.form-control:disabled{
    cursor: not-allowed !important;
}

.btn i,
.btn-sm,
.btn-group-sm > .btn {
    border-radius: 0px !important;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-radius: 0px !important;
}

.table-hover > tbody > tr:hover table-hover > tbody > tr {
    background-color: #edf9ff;
}

.table-hover > tbody > tr:hover {
    background-color: #edf9ff;
    cursor: pointer;
}

.row-selected {
    background-color: #caedff !important;
}

label,
table {
    font-size: 12px;
}

.nav-tabs.nav-justified > li > a {
    border-radius: 0px !important;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
    border-top: 4px solid #0af;
}

.nav.nav-tabs > a.nav-item.active,
.nav.nav-tabs > a.nav-item:hover,
.nav.nav-tabs > a.nav-item:focus {
    border-top: 4px solid #0af;
}

.bootstrap-grid-no-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.bootstrap-grid-sm-padding {
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-bottom: 10px;
}

.bootstrap-grid-md-padding {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.tag-label {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-right: 5px;
    font-size: smaller;
}

.tab-content-container {
}

.tab-content-container-left-card {
}

.tab-content-container-middle-card {
    padding: 15px 15px 0px 15px;
    background-color: grey;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}

.tab-content-container-middle-card-no-content {
    padding: 15%;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}

.tab-content-container-right-card {
}

.tab-no-content-message {
    color: white;
}

/* ===== Begin Procee Return Modal ===== */
.contentClassName {
    top: 0px !important;
}
/* Need to remove the header*/

.contentClassName {
    top: 0px !important;
}
/* Need to remove the header*/
.custom-window {
    position: relative;
    height: 100%;
    font-size: 14px;
}

.custom-window .custom-window-header {
    background: rgb(241, 236, 236);
    padding: 10px 15px;
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    line-height: 30px;
}

.custom-window .custom-window-header .header-inner {
    position: relative;
    padding-right: 130px;
    padding-left: 35px;
}

.custom-window .custom-window-header .header-inner .fa-rocket {
    color: gray;
    font-size: 22px;
    position: absolute;
    left: 0px;
    top: 4px;
}

.custom-window .custom-window-header .header-inner .title {
    color: #1a8fbf;
    font-size: 16px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.custom-window .custom-window-header .header-inner .header-btn {
    position: absolute;
    top: 0px;
    right: 0px;
}

.custom-window .custom-window-header .header-inner .header-btn button {
    width: 24px;
    height: 24px;
    font-size: 14px;
    margin-left: 2px;
    background: transparent;
    border: 1px solid transparent;
    color: #000000;
    opacity: 0.4;
    line-height: 24px;
}

.custom-window .custom-window-header .header-inner .header-btn button:hover {
    border: 1px solid #c1c1c1;
    opacity: 0.6;
}

.custom-window .custom-window-content {
    padding: 65px 15px 85px;
    height: 100%;
}

.custom-window .custom-window-content .nav-tabs {
    margin-bottom: 10px;
}

.custom-window .tab-content {
    height: calc(100% - 50px);
}

.custom-window .tab-content .tab-pane,
.custom-window .tab-content .tab-pane .h100 {
    height: 100%;
}

.custom-window .custom-window-content .pr-right-pannel,
.custom-window .custom-window-content .pr-left-pannel,
.custom-window .custom-window-content .pr-center-pannel {
    position: relative;
    height: 100%;
}

.custom-window .custom-window-content .scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    padding-right: 1px;
}

.custom-window .custom-window-content .right-panel .scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: initial;
    top: 0px;
    bottom: 0px;
    left: 0px;
    padding-right: 1px;
}

.custom-window .custom-window-content .left-panel .scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: initial;
    top: 0px;
    bottom: 0px;
    left: 0px;
    padding-right: 1px;
}

.custom-window .custom-window-content .box {
    height: 320px;
    display: block;
    background: #ccc;
    margin-bottom: 10px;
}

.custom-window .custom-window-footer {
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: white;
}

.custom-window .custom-window-footer .btn + .btn {
    margin-left: 10px;
}

.custom-window .accordion {
    margin-bottom: 10px;
}

.custom-window .tab-content .btn-toolbar {
    display: inline-block;
}

.custom-window .bookmarksPane .card-header h3 {
    font-size: 16px;
    font-weight: 400;
    background-color: inherit;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;
}

#process-return-groups {
    height: 100%;
    position: relative;
}

.input-group.date > input[type="text"] {
    z-index: 0 !important;
}

.multi-select-widthauto .Select-menu-outer {
    z-index: 999 !important;
    width: auto !important;
    font-size: 11px !important;
}

.multi-select-width100 .Select-menu-outer {
    width: 100% !important;
    font-size: 11px !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.svg-shadow {
    filter: drop-shadow(10px 10px 5px #dadada);
}

.sort-column[title="Status"],
.sort-column[title="Tax Year"],
.sort-column[data-field="documentStatus"],
.sort-column[data-field="signatureStatus"] {
    overflow: inherit !important;
}

.react-bs-container-header {
    overflow: inherit !important;
}

.Select-multi-value-wrapper {
    padding-left: 8px;
}

.Select-multi-value-wrapper span:not(:first-child) {
    display: none;
}

.Select-multi-value-wrapper span:first-of-type {
    display: inline-block !important;
}

.textalign-right {
    text-align: right;
}
.color-lgreen {
    color: #4ab14a;
}

.upload-doc-modal .prosystem-upload-doc-body {
    overflow: auto;
}

.edit-upload-modal {
    width: 70%;
    margin-left: 15%;
}

/* SF */
.sf-container {
    width: 100%;
    margin-top: 8px;
}

.sf-progressbar {
    counter-reset: step;
}

.sf-progressbar li {
    list-style: none;
    display: inline-block;
    width: 25%;
    position: relative;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
}

.sf-progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 27px;
    border: 1px solid #ddd;
    border-radius: 100%;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff;
}

.sf-progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #ddd;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.sf-progressbar li:first-child:after {
    content: none;
}

.sf-progressbar li.sfstep-active:before {
    border-color: #1d9bff;
    background-color: #1d9bff;
    color: white;
}

.sf-progressbar li.sfstep-completed {
    color: rgb(0, 128, 0);
}

.sf-progressbar li.sfstep-completed:before {
    border-color: rgb(0, 128, 0);
    content: "\2713";
    background-color: rgb(0, 128, 0);
    color: white;
}

.sf-progressbar li.sfstep-completed + li:after {
    background-color: rgb(0, 128, 0);
}

.panel-footer .btn + .btn {
    margin-left: 10px;
}

.sf-panel-header {
    background-color: white;
    position: sticky;
    top: 0px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: white;
    /* font-size: 3rem; */
    /* position: relative; */
    z-index: 10;
    height: 100px;
}

.sf-panel-footer {
    position: absolute;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: right;
    -ms-flex-align: center;
    align-items: right;
    -ms-flex-pack: center;
    justify-content: flex-end;
    background-color: white;
    background-color: white;
    text-align: right;
    margin: -1px;
}
.tran-div-pad {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 0px;
}
.height-150p {
    height: 150px;
}
.txt-right-align {
    text-align: right;
}
.txt-font-bold {
    font-weight: bold;
}
.width15per {
    width: 15% !important;
}
.width10per {
    width: 10% !important;
}
.padding-lft {
    padding-left: 0px;
}
.pad-bottom-10p {
    padding-bottom: 10px;
}

.text-center-align {
    text-align: center !important;
}
.height-300p {
    height: 300px;
}

.text-left-align {
    text-align: left !important;
}

#sfUploadDocumentTable .react-bs-table-container .react-bs-table .react-bs-container-body {
    overflow: inherit;
}

.sfPanel-body {
    position: relative;
    overflow-x: hidden;
    overflow: auto;
    height: calc(100vh - 230px);
}

.custom-sfPanel-body {
    position: relative;
    overflow-x: hidden;
    overflow: hidden;
    height: calc(100vh - 230px);
}

.custom-sfPanel-body .tab-content-container {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container-left-card {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container-middle-card {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container-right-card {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container .pr-right-pannel,
.custom-sfPanel-body .tab-content-container .pr-left-pannel,
.custom-sfPanel-body .tab-content-container .pr-center-pannel {
    position: relative;
    height: 100%;
}

.custom-sfPanel-body .scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
}

.custom-sfPanel-body .box {
    height: 320px;
    display: block;
    background: #ccc;
    margin-bottom: 10px;
}

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-style: normal;
    font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
    content: "\e114"; /* adjust as needed, taken from bootstrap.css */
    float: right; /* adjust as needed */
    color: grey; /* adjust as needed */
    font-style: normal;
}

.accordian-panel .panel .panel-heading .panel-title .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    font-style: normal;
    font-family: "Glyphicons Halflings"; /* essential for enabling glyphicon */
    content: "\e080"; /* adjust as needed, taken from bootstrap.css */
    font-style: normal;
}

.sf-receipient-signer-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
    box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
}

.sf-sender-signer-sign-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
    box-shadow: 0px 0px 10px 5px rgba(247, 241, 126, 1);
}

.mar-B0 {
    margin-bottom: 0px !important;
}

.bookmarksPane .accordian-panel .panel {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
}

#mailMergeUploadCsvTable {
    padding-top: 7%;
}

#mailMergeUploadCsvTable .react-bs-table-container .react-bs-table .react-bs-container-body {
    overflow: inherit;
}

#mailMergeUploadCsvTable .react-bs-container-header.table-header-wrapper {
    display: none;
}

.invalidMailMergeCellValue {
    color: red;
    cursor: pointer;
}

#div-validation-btn {
    height: 29vh;
    background-color: lightgrey;
    text-align: center;
    padding-top: 8vh;
}

#div-validation-btn div {
    margin-top: 10px;
}

.mailmerge-custom-field {
    border: none;
}

.mailmerge-custom-field-tag {
    background-color: #66afe930;
    border-radius: 5px;
    border: 1px solid #3791d8;
    color: black;
    display: inline-block;
    font-family: sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
    cursor: pointer;
}

.mailmerge-custom-field-tag-remove {
    color: #274c96;
    padding-left: 5px;
    cursor: pointer;
}

.custom-field-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
    box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
}

#pdfpreview .modal-content {
    display: inline-block;
}

.csv-table-td-error {
    outline-color: red;
    outline-style: solid;
    outline-width: 3px;
    outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-error {
    outline: 3px solid red !important;
    outline-offset: -1px;
}

.react-bs-table .table-bordered > tbody > tr > td.csv-table-td-warning {
    outline: 3px solid orange !important;
    outline-offset: -1px;
}

.margin-tbl-20 {
    margin: 20px 0 20px 20px;
}

.margin-top-10 {
    margin-top: 10px;
}

.beforeContent {
    position: relative;
}

.beforeContent::before {
    content: "-";
    position: absolute;
    left: -10px;
}

@media screen and (min-width: 1360px) {
    #ddlUploadSFDocument .Select-arrow-zone {
        width: 15px;
    }
}

.fa-btn-icon {
    padding: 4px 0;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
    font-size: 12px;
}

.no-padding-left {
    padding-left: 0px !important;
}

#group-process-return-tab {
    height: 100%;
    position: relative;
}

.multi-select-control .Select-menu-outer {
    width: 100%;
    font-size: 11px;
    padding-top: 0.3rem;
}

.Select-control {
    table-layout: fixed;
}

.Select-input {
    overflow: hidden;
    max-width: 100%;
}

.groupNameFilter .Select-value-label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.groupNameFilter .Select-input {
    padding-left: 2px !important;
}

.groupNameFilter .Select-placeholder {
    color: #ccc !important;
    font-style: italic !important;
}

.groupNameFilter .Select-menu-outer {
    min-width: 180px !important;
    max-width: 200px !important;
}

.groupNameFilter .Select-menu-outer .Select-option {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 3px 14px;
    word-wrap: break-word;
    white-space: normal;
}

.checkbox-text-container:nth-child(1) {
    margin-top: 4px;
}

.disable-link {
    pointer-events: none;
}

.group-input {
    height: 36px;
}

#datepicker_input div {
    min-width: 0px;
}

#datepicker_input div .react-datepicker {
    top: 100%;
}

/* CustomEngagementTypeFilter styles start */
.typeRadio_label {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}

.typeRadio_label input {
    margin-right: 8px;
}

.typeText {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}

.typeTickIcon {
    margin-left: 4px;
    display: flex;
    align-items: center;
}

.typeClearAll {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #0973BA;
    margin-top: 8px;
    margin-bottom: 6px;
}

.typeClearAll.selectedText {
    margin-right: 10px;
    cursor: default;
}

.typeHorizontalLine {
    height: 10px;
}

.typeContainer {
    padding: 0 12px;
}

.typeRadio_label {
    display: flex;
    align-items: center;
}

.typeRadio_label input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 13px;
    height: 13px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
}

.typeRadio_label input[type="radio"]:checked {
    border: 3.5px solid #0973BA;
}

.type_FullReturn {
    margin-top: 4px;
    margin-bottom: -0.5px;
}

.type_StateOnly {
    margin-bottom: -0.5px;
}

.type_selectType {
    margin-top: 8px;
    font-weight: 600;
}

.typeAll {
    margin-bottom: -6px;
}

.selected-control {
    display: flex;
    align-items: center;
    background-color: #E6F1F8;
    margin: 3px 0;
}

.draftReturn-custom-tag-styles {
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: -6px;
    padding-right: 5px;
    max-width: 25px;
}

.custom-tag-styles {
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: -6px;
    padding-right: 5px;
    max-width: 10px;
}

.custom-option-all-style {
    max-width: 100% !important;
}
/* CustomEngagementTypeFilter styles end */

.react-bs-table .table-bordered > thead > tr:first-child > th {
    overflow: visible;
}

.zero-margin {
    margin-bottom: 0 !important;
}

.overlay-height {
    height: 500px;
}

.phone-number-input {
    height: 36px;
}

.calendarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.calendarInput {
    position: absolute;
    z-index: 1000;
    top: 30px;
}

.calendarClass {
    min-width: 0px;
    display: inline-block;
    position: relative;
    width: 100%;
}

.inputClass {
    width: 100%;

    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    font-size: 12px;
    padding-left: 6px;
}

.minusClass {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    border-radius: 0px 3px 3px 0px;
    height: 100%;
    font-family: monospace;
}

.minusClass:hover {
    cursor: pointer;
}

#dateId .dateClass .react-datepicker-container .react-datepicker-top {
    background: #ddd;
    color: black;
    border-color: #ddd;
}

.react-bs-table-container .react-bs-table .react-bs-container-body td .button-group button i,
.react-bs-table-container .react-bs-table .react-bs-container-body .button-cell button i {
    padding: 4px;
}

.btn-group-vertical {
    display: inline-flex !important;
}

.react-bs-table-container .react-bs-table .react-bs-container-body td .button-group .dropdown-toggle::after,
.app-header-menu-item .dropdown-toggle::after {
    display: none;
}

/*---------------User Management Start----------*/

.user-group-roles-label {
    display: inline;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.label-success {
    background-color: #5cb85c;
}

.label-info {
    background-color: #5bc0de;
}

.label-danger {
    background-color: #d9534f;
}

.label-warning {
    background-color: #f0ad4e;
}
.label-primary {
    background-color: #0d47a1;
}

.addUserModal .modal-dialog,
.editUserModal .modal-dialog,
.ero-signature .modal-dialog {
    max-width: 100%;
    font-size: 14px;
}

.ero-signature-delegation .modal-content {
    width: 600px !important;
    margin: auto;
}

.user-management-edit-password .modal-content,
.edit-company-information .modal-content,
.edit-primary-admin .modal-content {
    width: 600px !important;
    margin: auto;
}

/*---------------User Management End----------*/

/*---------------App Header Start-------------*/

.change-password-modal .modal-content,
.logout-confirmation .modal-content,
.header-my-download .modal-content,
.header-my-account-modal .modal-content,
.session-timeout-modal .modal-content,
.session-expired-modal .modal-content {
    width: 600px !important;
    margin: auto;
}

.report-problem-modal .modal-content {
    width: 800px !important;
    margin: auto;
}

.my-settings .modal-content,
.my-login-history .modal-content {
    width: 900px !important;
    margin: auto;
}

.app-header-menu-item ul li a {
    text-decoration: none;
}

.app-header-menu-item ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
/*---------------App Header Stop--------------*/

.modal-content {
    font-size: 14px;
}

/*---------------Pagination Start----------------*/
.page-item.active .page-link {
    background-color: #337ab7;
    border-color: #337ab7;
}

a {
    color: #337ab7;
}

.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.15;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.react-bs-table-pagination p {
    font-size: 14px;
}
/*---------------Pagination End----------------*/

/*---------------Settings Start----------------*/
.mfa-settings .modal-content,
.o365-group .modal-content,
.add-edit-message .modal-content,
.edit-column-value .modal-content,
.return-level-kba-check .modal-content,
.taxCaddy-reporting-channel .modal-content,
.ssr-signature-settings .modal-content,
.ssr-organizer-settings .modal-content,
.stripe-account-details .modal-content,
.edit-company-logo .modal-content,
.edit-company-white-logo .modal-content,
.edit-color-palette .modal-content,
.dependent-document-modal .modal-content {
    width: 600px !important;
    margin: auto;
}

.add-edit-message .modal-content .form-group {
    display: block;
    margin-bottom: 1rem;
}

.file-preview-modal .modal-content {
    width: 800px !important;
    margin: auto;
}

.mfa-settings .react-bs-table-tool-bar .row {
    display: block;
}

.manual-signature-options .row {
    display: block;
}

.watermark-settings {
    min-width: 85vw !important;
}
/*---------------Settings End----------------*/

/*---------------Reports Start--------------*/

.payment-success-modal .modal-content,
.delivered-resend-access-link .modal-content,
.report-change-status-modal .modal-content,
.set-column-value-modal .modal-content,
.modify-retention-period-modal .modal-content,
.download-returns-modal .modal-content,
.column-options-modal .modal-content,
.group-configuration-modal .modal-content,
.change-status-modal .modal-content,
.edit-client-info .modal-content,
.screen-share-modal .modal-content,
.preparer-message .modal-content,
.edit-controller-info .modal-content,
.grouped-resend-access-link .modal-content,
.grouped-uploaded-documents .modal-content,
.save-filters-modal .modal-content,
.download-attachments-modal .modal-content {
    width: 600px !important;
    margin: auto;
}
.change-default-warning-modal .modal-content {
    width: 500px !important;
    margin: auto;
}

.attachment-instruction-modal .modal-content {
    width: 700px !important;
    margin: auto;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu {
    position: fixed !important;
    z-index: 1001;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu .dropdown-submenu .dropdown-menu {
    position: absolute !important;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li {
    padding-top: 2px;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li a {
    color: black;
    font-size: 14px;
    padding-right: 5px;
    text-decoration: none;
}

.add-multiple-payment .table th,
.add-multiple-payment .table td {
    padding: 0.3rem;
}

.tab-content-container-right-card .pr-left-pannel .scroll .accordion .card-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0;
}

.tab-content-container-right-card .pr-left-pannel .scroll .accordion .card-body .form-group .form-label {
    margin-bottom: 0;
    word-wrap: normal;
}

#move-to-dropdown {
    padding: 6px;
    margin-right: 3px;
    background-color: #f0ad4e;
    color: white;
}

.InternalRouting-dropdown-Menu .dropdown-toggle::after,
.Delivery-dropdown-Menu .dropdown-toggle::after {
    display: none;
}

.save-filters-modal .form-control:focus {
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
/*---------------Reports Start--------------*/
/*---------------Billing Start--------------*/
.billing-custom-date-handler {
    display: contents;
}

.billing-custom-date-handler .control-pane {
    width: 100%;
}

.add-card-modal .modal-content,
.transaction-successful-modal .modal-content {
    width: 600px !important;
    margin: auto;
}

.purchase-return-modal .modal-content {
    width: 900px !important;
    margin: auto;
}

.purchase-return-modal .purchase-item-wrap .heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 10px;
}
/*---------------Billing End----------------*/

.settings-info-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: solid 1px #eee;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 12px;
    z-index: 999;
}

.settings-info-header .header-text {
    margin-top: 5px;
}

.position-fixed {
    position: fixed;
}

#menuDivide {
    position: relative;
    border: none;
    height: 0.2vh;
    background: lightgrey;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.last-login-modal .modal-content,
.download-options-modal .modal-content,
.replace-invoice-modal .modal-content {
    width: 600px !important;
    margin: auto;
}

.user-auto-logout-modal .modal-content {
    width: 500px !important;
    margin: auto;
}

.InternalRouting .nav-link:hover,
.Delivery .nav-link:hover {
    background-color: #eee;
}

.uploadpopupinfo {
    color: #212529;
    font-weight: normal;
    font-size: 14px;
    color: #373e4a;
}

.disable {
    cursor: not-allowed !important;
    opacity: 0.65 !important;
}

.prefilledField {
    background-color: #e6f1f8 !important;
}

.prefilledField .Select-value {
    background-color: #e6f1f8;
}
.prefilledField {
    .form-control {
    background-color: #e6f1f8 !important;
    }
    .flag-dropdown{
    background-color: #e6f1f8 !important;
    }
}


/* Import CCH Axcess Report */

.connection-status {
    font-size: 14px;
}

.connection-status span {
    vertical-align: middle;
}
.connection-status .status-indicator {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 2px;
}

.connected {
    background: #669440;
    border: 1px solid #669440;
}

.disconnected {
    background: #6b7075;
    border: 1px solid #6b7075;
}

.cch-modal .modal-content {
    width: 500px;
    margin: auto;
}

.cch-modal .modal-content .modal-body,
.filters-modal .modal-content .modal-body {
    margin: 0px 15px;
}

.cch-modal .modal-content .modal-title,
.filters-modal .modal-content .modal-title {
    color: #000000;
}

/* CCH Filters Popup Modal  */

.filters-modal .modal-content {
    width: 600px;
    margin: auto;
}

.filters-modal .Select-control {
    height: 38px;
    width: 200px;
    border-radius: 4px !important;
}

.filters-modal .Select-value {
    line-height: 38px;
}

.filters-modal .Select-placeholder {
    color: #565a5e !important;
}

.filters-modal .filter-row {
    display: flex;
    margin-bottom: 15px;
}

.filters-modal .filter-operator {
    height: 34px;
    width: 40px;
    align-content: center;
    margin-right: 15px;
    color: #05386b;
    margin-right: 10px;
}

.spacer {
    margin-right: 15px;
}

.filters-modal .icon-remove svg {
    height: 38px;
    cursor: pointer;
}

.filters-modal .filter-input {
    width: 200px;
    height: 38px;
    border-radius: 4px !important;
    font-size: 14px;
}

.filters-modal .btnAdd {
    background-color: transparent;
    border: none;
}

.filters-modal .btnAdd svg {
    margin-right: 10px;
    margin-bottom: 5px;
}

.filters-modal .modal-footer {
    padding: 0.5rem 0.6rem;
}

.filters-modal .Select.is-disabled > .Select-control,
.filters-modal .form-control:disabled {
    background-color: #f0f1f1 !important;
}

.filters-modal .form-control::placeholder {
    color: #565a5e !important;
}

.filters-modal .modal-body button,
.filters-modal .modal-footer button {
    width: 120px;
}

.filters-modal .btn-info:disabled {
    border-color: #c4c6c8 !important;
    background-color: #f0f1f1 !important;
    color: #c4c6c8 !important;
}

/* CCH Filters Popup Modal  */

.text-connected {
    color: #669440;
}

.status {
    padding: 2px 4px;
    border-radius: 4px;
    display: inline-block;
}

.status-new {
    background-color: #f0f4ec;
    border: 1px solid #47682d;
    color: #47682d;
}

.status-inprogress {
    background-color: #fbf0e7;
    border: 1px solid #a9570f;
    color: #a9570f;
}

.status-failed {
    background-color: #f7e8f0;
    border: 1px solid #8b1655;
    color: #8b1655;
}

.status-completed {
    background-color: #e6f1f8;
    border: 1px solid #075c95;
    color: #075c95;
}

.btn-right {
    float: right;
    min-width: 170px;
}

#divCCHAxcess .btn,
.cch-modal .btn {
    font-size: 16px;
}

#divCCHAxcess .btn.disabled,
#divCCHAxcess .btn:disabled {
    color: #c4c6c8;
}

table input[type="text"]::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ccc !important;
    font-style: italic !important;
}

table input[type="text"]::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccc !important;
    font-style: italic !important;
}

table input[type="text"]:-ms-input-placeholder {
    /* IE 10+ */
    color: #ccc !important;
    font-style: italic !important;
}

table input[type="text"]:-moz-placeholder {
    /* Firefox 18- */
    color: #ccc !important;
    font-style: italic !important;
}

/* CCH delete confirmation modal */
.CCH-delete-confirm-modal{
    .modal-content {
        width: 500px;
        min-height: 227px;
        border: 1px solid #00000033;
        border-radius: 4.8px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        z-index: 2;

        .modal-header {
            width: 498px;
            height: 57px; 
            display: flex;
            align-items: center;
            justify-content: center;
            width: Fixed (498px)px;

            .bootbox-dialog-title {
                height: 100%;
                color: #212529;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 700;
                width: 100%;

                .bootbox-dialog-title-text{
                    width: 422px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    font-family: Mulish;
                }

                .close{
                    font-size: 21px;
                    padding-top: 1.25rem;
                    font-family: Tahoma;
                }
            }
        }

        .modal-body {
            padding: 7px 14px 19px 16px;
                .bootbox-body{
                  
                    .bootbox-dialog-content-text {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #212529;
                        text-align: left;
                        width: 470px;
                        height: 79px;
                        font-family: Mulish;
                   }
               }
           }

        .modal-footer {
            width: 498px;
            height: 63px;
              
            .bootbox-dialog-actions {
                width: 498px;
                min-height: 63px;
                top: 163px;
                left: 1px;
                padding: 12px;
                gap: 8px;
                border-top: 1px solid #dee2e6;
            }
              
            .bootbox-button {
                width: 110px;
                height: 38px;
                padding: 6px 12px;
                gap: 8px;
                border: 1px solid;
                border-radius: 2px !important;
                margin: 0;
                font-family: Mulish;
                font-size: 16px;
            }
              
            .bootbox-button-cancel {
                border-color: #898d91;
                background-color: #f0f1f1;
                color: #212529;
                  
                .cancel-btn-text{
                    width: 66px;
                    height: 24px;
                }
            }
              
            .bootbox-button-proceed {
                border-color: #cc4a43;
                background-color: #cc4a43;
                color: #ffffff;
                margin-left: 8px;
  
                .proceed-btn-text{
                  width: 81px;
                  height: 24px;
                }
            }

            .bootbox .btn-secondary:hover {
                background-color: initial;
                color: initial;
                border: initial;
            }
              
            .bootbox .btn-primary:hover {
                background-color: initial; 
                color: #ffffff; 
                border: initial; 
            }
        }
    }
}

/* Import CCH Axcess Report */

/* TABS */
.leftAlignedTabHeader {
    display: flex;
    padding: 0.8rem 0 0 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    border-bottom: 1px solid #a6a9ac;
}

.leftAlignedTabHeader .tabSelected {
    color: #212529;
    border-top: 3px solid #0973ba;
    font-weight: 600;
    cursor: default;
    background: #e6f1f880;
    margin-top: 1px;
    margin-bottom: -1px;
    border-bottom: 1px solid #c6ccd2;
}

.leftAlignedTabHeader li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 273px;
    height: 43px;
    padding: 0.5rem 1rem;
    font-weight: 400;
    cursor: pointer;
    color: #212529;
    line-height: 24px;
}

.nameColumnClass {
    overflow: visible !important;
}

.nameColumnClass .nameCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameColumnClass .nameCell .linkIconContainer {
    display: flex;
    align-items: center;
    margin-top: -5px !important;
}

.nameColumnClass .nameCell .linkIconContainer button {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer button:active {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer button:focus {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-toggle::after {
    display: none;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu {
    translate: -50%;
    cursor: default;
}

.draftReturnCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.draftReturnCell .ellipsis {
    width: calc(100% - 13px);
}

.dropdown-menu.show[x-placement="top-start"] {
    margin-bottom: 5px !important;
}

.dropdown-menu.show[x-placement="top-start"]:after {
    content: url("src/assets/images/arrow-down.svg");
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    bottom: 9px;
}

.dropdown-menu.show[x-placement="bottom-start"] {
    margin-top: 4px !important;
}

.dropdown-menu.show[x-placement="bottom-start"]:before {
    content: url("src/assets/images/arrow-up.svg");
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    top: -18px;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer {
    padding: 0 1rem;
    width: 350px;
    max-width: 350px;
    min-height: 100px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: normal;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .connectedStyle {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-decoration: underline;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData {
    padding-top: 0rem;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkClientName {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 1rem;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData div .linkClientName:first-child {
    margin-top: 0.3rem;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkClientId {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #565a5e;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkEmailId {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkPrimaryUTE .emailId {
    color: #0973ba;
}

.primaryUTEEmailId {
    color: #05386B;;
}

.customClassCC .Select-value-label {
    width: 100%;
    overflow: hidden;
}

.customClassCC .Select-clear {
    margin-left: 3px;
}

.table-status-btn {
    font-size: 14px;
    padding: 0px 8px 0px 8px;
    border-radius: 4px;
    border: 1px solid;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: bottom;
    margin-left: 2px;
}
.status-btn-shamrock {
    background-color: var(--shamrock-100);
    color: var(--shamrock-700);
    border-color: var(--shamrock-700);
}

.status-btn-sapphire {
    background-color: var(--sapphire-100);
    color: var(--sapphire-600);
    border-color: var(--sapphire-600);
}

.status-btn-orange {
    background-color: var(--orange-100);
    color: var(--orange-600);
    border-color: var(--orange-600);
}

.status-btn-gray {
    background-color: var(--gray-100);
    color: var(--gray-600);
    border-color: var(--gray-600);
}

.status-btn-magenta {
    background-color: var(--magenta-100);
    color: var(--magenta-600);
    border-color: var(--magenta-600);
}

:root {
    --shamrock-100: #f0f4ec;
    --shamrock-700: #47682d;
    --sapphire-100: #e6f1f8;
    --sapphire-600: #075c95;
    --orange-100: #fbf0e7;
    --orange-600: #a9570f;
    --gray-100: #898d91;
    --gray-600: #565a5e;
    --magenta-100: #f7e8f0;
    --magenta-600: #8b1655;
}

.react-tel-input .form-control {
    width: 100% !important;
}

.react-tel-input .country-list {
    width: 240px !important;
}

/* Draft Returns */

.draft{  
    border-radius: 100px;
    background: #6B7075;
    margin-left: 15px;
    padding: 1.5px 13px 2.5px 13px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
}

.tooltip {
    display: block;
    color: #212529; 
    width: 314px; 
    position: absolute; 
    background-color: #D9D9D9; 
    padding: 5px;
    border-radius: 2px; 
    z-index: 100;
    margin-top: 10px;
    box-shadow: 0px 2px 4px 0px #00000013;
    visibility: hidden;
    opacity: 0; 
}

span:hover .tooltip {
    visibility: visible; 
    opacity: 1;
}

.disabledDropDown{
    cursor: not-allowed !important;
}

.custom-draft-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8) ;
    z-index: 1065 ;
}

.custom-draft-modal .modal-dialog { 
    display: flex;
    margin: auto !important;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.custom-draft-modal .modal-content {
    border-radius: 4.8px !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    border: 1px solid #00000033;
    width: 450px;
    height: 232px;
    font-family: Mulish;
}

.custom-draft-modal .modal-header {
    border-bottom: 1px solid #DEE2E6;
    height: 57px;
}

.custom-draft-modal .modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #212529;
    display: flex;
    justify-content: space-between;
    padding: 8.5px 0px;
}

.custom-draft-modal .modal-body {
    font-size: 16px;
}

.custom-draft-modal .modal-footer{
    height: 63px;
    padding: 8px;
}
.custom-draft-modal .draftAttentionBtn {
    border-radius: 2px !important;
    width: 125px;
    height: 38px;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 10px 6px 10px;
}

.custom-draft-modal .draftAttentionBtn-left {
    background-color: #CC4A43; 
    border: 1px solid #CC4A43;
    color: #FFFFFF;
}

.custom-draft-modal .draftAttentionBtn-right {
    background-color: #669440;
    border: 1px solid #669440;
    color: #FFFFFF;
    margin-left: 4px !important;
}

.custom-close-icon{
    position: relative;
    bottom: 1px;
    cursor: pointer;
}

.action-body {
    display: inline-flex;
    margin-bottom: 20px;
}

.action-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1065;    
}
    .action-modal .modal-content {
        border-radius: 4.8px !important;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
        border: 1px solid #00000033; 
        font-family: Mulish;
    }
    .action-modal .modal-dialog {
        width: 503px;
        height: calc(100vh - 3.5rem); 
        display: flex;
        align-items: center;
    }

    .action-modal .modal-content .modal-body {
        font-size: 16px;
    }

    .action-modal .modal-content .modal-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #212529;
        display: flex;
        justify-content: space-between;
    }

    .action-modal .modal-header {
        border-bottom: 1px solid #DEE2E6
    }

    .action-modal .closeBtn {
        border-radius: 2px;
        width: 100px;
        height: 38px;
        font-size: 16px;
        font-weight: 400;
        padding: 6px 10px 6px 10px;
        background-color: #0973BA;
        border: 1px solid #0973BA;
        color: #FFFFFF;
    }
    .action-modal .noBtn {
        border-radius: 2px;
        width: 125px;
        height: 38px;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 10px 6px 10px;
        background-color: #CC4A43;
        border: 1px solid #CC4A43;
        color: #FFFFFF;
    }
    .action-modal .yesBtn {
        border-radius: 2px;
        width: 125px;
        height: 38px;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 10px 6px 10px;
        background-color: #669440;
        border: 1px solid #669440;
        color: #FFFFFF;
        margin-left: 4px !important;
    } 
